.color {
    width: 40px;
    height: 15px;
    border-radius: 3px;
    /* background: rgba(225, 155, 99, 2); */
}

.popover {
    position: absolute;
    z-index: 3;
}

.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.swatch {
    padding: 6px;
    background: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    box-shadow: 0 0 0 1px rgba(0,0,0,.2);
}