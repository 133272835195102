#grid{
     /* backgroundColor: #000;; */
     margin-Top: 8%;
     box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
.nav-fill .nav-item{
     flex: none;
}
.nav-tabs .nav-link.active {
     background-color: lavender;
 }