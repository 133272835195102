h1 {
    text-align: center;
  }
  
  .box {
    width: 330px;
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 auto;
    border: 1px solid #AAA;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  }
  
  .item {
    width: 330px;
    height: 31px;
    border: 1px solid #DDD;
    font-size: 13px;
    line-height: 35px;
    text-align: center;
    color: #333;
    cursor: pointer;
    position: relative;
    transition: all .3s;
    user-select: none;
    cursor: all-scroll;
  }
  
  .item.onDrag {
    /*transform: scale(1.05, 1.1);*/
    opacity: 1;
    background-color: #F5F5F5;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
  }
  
  
  
  .item:last-child {
    height: 20px;
  }
  
  .item:last-child::before {
    border: none;
    height: 0;
  }
  
  .itemClip {
    position: absolute;
    background-color: white;
    opacity: 1;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    transition: none;
    background-color: white;
  }
  
  .hide {
    display: none;
  }
  
  .chkbox::before {
    top: 20px;
    left: 20px;
  }
  
  .chkbox {
    cursor: pointer;
    float: left;
  } 
  .red{
    color: red;
  }
  .green{
    color: green;
  }
  .opa1{
   opacity: 1;
  }
  .opa05{
  opacity: 0.5;
  }