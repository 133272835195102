
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/*dynamic table file upload*/

.nav-pills-custom .nav-link {
  color: #aaa;
  background: #fff;
  position: relative;
}

#v-pills-tab {
  position: fixed;
  width: 13rem;
}

.mycustomClass-OverFlow {
  max-height: 17.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.hdcol {
  color: #45b649 !important
}
.modal-footer {
  border-top: none !important;
}
.modal-footer {
  padding: 1.5rem !important;
}
.nav-pills-custom .nav-link.active {
  color: #45b649 !important;
  background: #fff !important;
}

.nav-pills-custom .nav-link {
  color: #6c757d !important;
}

.custom-btn-submit-cancel {
  left: 63rem;
  position: absolute;
  top:200%;
}
.candidate-modal-body {
  height: 60vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.custom-btn-submit-cancel1 {
  left: 58rem;
  position: absolute;
  top: 200%;
}
@media (min-width: 992px) {

  .newcandidate-lg-modal>.modal-lg {
    max-width: 70rem !important;
  }
}
.selectperPage{
  color: #007bff;
  background-color: #fff;
  border: 1px solid #007bff;
}
.recordperPage{
  color: #007bff;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
    height: 65%;
    padding-top: 2%;
    padding-left: 10%;
}
#resp-table {
  width: 100%;
  display: table;
}
#resp-table-body{
  display: table-row-group;
}
.resp-table-row{
  display: table-row;
}
.table-body-cell{
  display: table-cell;
  border: 1px solid #dddddd;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
.file-upload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.file-upload .file-select {
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select .file-select-button {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.file-upload .file-select:hover {
  border-color: #34495e;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
  background: #34495e;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload .file-select input[type=file] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

/*comment*/
.commcan {
  float: left;
  width: 735px;
   background: url(../src/img/arrow_up.gif) no-repeat 20px top; 
  padding-top: 10px;
  margin-bottom: 10px;
}
.ccanbox {
  border-radius: 10px;
  float: left;
  width: 715px;
  background-color: #edeff4;
  padding: 10px;
  line-height: 20px;
}
.ccanbox p {
  padding-bottom: 3px;
  color: #333333;
  word-wrap: break-word;
}
.ff {
  float: left;
  width: 100%;
}
.ccanbox .fl {
  float: left;
}
.ccanbox .fr {
  float: right;
}
.fr {
  float: right;
  padding-left: 20px;
  padding-right: 2px;
  margin-top: 23px;
}
.pl20 {
  padding-left: 20px;
}
.fcomestb {
  background-color: #e7e7e7;
  padding: 5px;
  line-height: 25px;
  margin-left: 10px;
  word-wrap: break-word;
}
.rowGap10 {
  height: 10px;
}
/* share folder */
#lstBox1, #lstBox2{
  height:185px;
  width:175px;
}

.approveTermsContainer{
  display:flex;
  justify-content:center;
}

.transferBtns {
  margin-right: 30px;
  margin-left: 30px;
}

.transferBtns{
  float:left; 
  align-self:center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.nav-tabs .nav-link.active{
  color:#1976d2;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: darkkhaki;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.plus {
  display: table-cell !important;
}

.minus {
  display: none !important;
}

.table td,
.table th {
  border-top: none !important;
}

.font-18 {
  font-size: 18px;
  color: #28A745;
}

.font-13 {
  font-size: 13px;
}

.font-11 {
  font-size: 11px;
  font-style: italic;
  color: #949494;
}

.text-danger {
  font-size: 12px;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 879px !important;
  }
}

.eSTlnH {
  width: 85% !important;
}

.gelpCx {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}

.react-datepicker-ignore-onclickoutside {}

.datePicker,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker__input-container {
  width: inherit;
  height: inherit;
}

.react-datepicker__input-container input {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}


.div-table {
  display: table;
  font-size: 12px;
  width: 100%;
  background-color: #fff;
  border-spacing: 5px;
  /* cellspacing:poor IE support for  this */
}

.div-table-row {
  display: table-row;
  width: 100%;
  clear: both;
}

.div-table-col {
  float: left;
  /* fix for  buggy browsers */
  display: table-column;
  width: 145px;
  background-color: #fff;
}

.job-title-body {
  color: #00559c !important;
  padding: 8px 5px 8px 0px;
  margin: 2px 2px 0px 0px;
}

.title-body-link {
  color: #00559c !important;
}

.title-body-link:hover {
  text-decoration: none !important;
}

th,
td {
  font-size: 12px;
  color: #6c6c6c;
}

.table td,
.table th {
  padding: 5px !important;
  vertical-align: inherit !important;
  border-top: 1px solid #dee2e6;
}

/* Loading animation */
.loadingDiv {
  color: #28A745;
  font: 300 4em/150% Impact;
  text-align: center;
}

.loadingDiv:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #28A745;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #28A745,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #28A745,
      .5em 0 0 #28A745;}}


      @media (max-width:767px) {
          .text-mob-heading-center{
            text-align: center !important;
            margin-left: 0px !important;
          }
          .margin-mob-top{
            margin-top: 1rem;
          }
      }

      .bold{
        font-weight: 500;
      }

@media (max-width:767px) {
  .adjust-mob{
    margin-left: 0 !important;
  }
  .btn-right{
    margin-left: 15%;
  }
}

.font-12{
  font-size: 12px;
}
.font-500{
  font-weight: 500;
}












.dropbtn {
  color: white !important;
  text-decoration: none !important;
  list-style: none;
}


.dropdown {
  float: left;
}


.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 12px !important;
}

.cutom-parent-link {
  color: #fff !important;

}

.cutom-parent-link :hover {
  color: #fff !important;

}

a:hover {
  text-decoration: none !important;
}

.dropdown {
  display: inline-block;
  right: 32%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 14px;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 13rem;
  overflow: auto;
  font-size: 11px;
}

.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #09c;
  color: white;
}

.dropdown-content1 a:hover {
  background-color: #09c;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown1:hover .dropdown-content1 {
  display: block;
}
.custom-card{
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  background: #1976d2;
  color: #fff;
}

.cls-njpa{
  color: red;
  margin-left: 33%;
  margin-top: 1%;
  margin-bottom: 1%;
}