.loader {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: absolute;
    top: 41%;
    left: 41%;
    background: #cccccc;
    box-shadow: -24px 0 #cccccc, 24px 0 #cccccc;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
    z-index: 99999;
  }
  
  @keyframes shadowPulse {
    20%{
        background: #FF3D00;
        box-shadow: -24px 0 #cccccc, 24px 0 #cccccc;
    }
    40% {
      background: #cccccc;
      box-shadow: -24px 0 #FF3D00, 18px 0 #cccccc;
    }
    60%{
        background: #FF3D00;
        box-shadow: -24px 0 #cccccc, 24px 0 #cccccc;
    }
    80%{
        background: #cccccc;
        box-shadow: -24px 0 #FF3D00, 18px 0 #cccccc;
    }
    100%{
        background: #FF3D00;
        box-shadow: -24px 0 #cccccc, 24px 0 #cccccc;
    }
   
  }